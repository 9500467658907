<template>
  <div v-if="isMobile" class="phoneProductBox ">
    <ul v-if="id">
      <div v-for="(item,index) in projectList" :key="index" class="concentBox">
        <li v-for="child in item.itemList" :key="child.id" style="font-size: 20px;background: transparent;">
          <img :src="constUrl+child.picture" style="width: 100%"/>
          <div class="details">
            <div class="details_title">
              {{child.name}}
            </div>
            <div class="details_concent">
              <div class="detail">{{child.detail}}</div>
            </div>
            <div class="details_btn">
              <el-button @click="toDetails(child.id,child.picture,child.name)">查看详情→</el-button>
            </div>
          </div>
        </li>
      </div>
    </ul>
    <ul v-else>
      <div class="concentBox">
        <li v-for="child in projectList" :key="child.id" style="font-size: 20px;background: transparent;">
          <img v-if="child.picture.length!==0"
               :src="constUrl+child.picture"
               style="height: 100%"
          />
          <img v-else :src="imgSrc" style="width: 100%;height:100%"/>
          <div class="details">
            <div class="details_title">
              {{child.name}}
            </div>
            <div class="details_concent">
              <div class="detail">{{child.detail}}</div>
            </div>
            <div class="details_btn">
              <el-button @click="toDetails(child.id,child.picture)">查看详情→</el-button>
            </div>
          </div>
        </li>
      </div>
    </ul>
  </div>
  <div v-else class="productBox">
    <ul v-if="id">
      <div v-for="(item,index) in projectList" :key="index" class="concentBox">
        <li v-for="child in item.itemList" :key="child.id" style="font-size: 20px;background: transparent;">
          <img :src="constUrl+child.picture" style="width: 100%"/>
          <div class="details">
            <div class="details_title">
              {{child.name}}
            </div>
            <div class="details_concent">
              <div class="detail">{{child.detail}}</div>
            </div>
            <div class="details_btn">
              <el-button @click="toDetails(child.id,child.picture)">查看详情→</el-button>
            </div>
          </div>
        </li>
      </div>
    </ul>
    <ul v-else>
      <div class="concentBox">
        <li v-for="child in projectList" :key="child.id" style="font-size: 20px;background: transparent;">
          <img v-if="child.picture.length!==0"
               :src="constUrl+child.picture"
               style="height: 100%"
          />

          <img v-else :src="imgSrc" style="width: 100%;height:100%"/>
          <div class="details">
            <div class="details_title">
              {{child.name}}
            </div>
            <div class="details_concent">
              <div class="detail">{{child.detail}}</div>
            </div>
            <div class="details_btn">
              <el-button @click="toDetails(child.id,child.picture,child.name)">查看详情→</el-button>
            </div>
          </div>
        </li>
      </div>
    </ul>
  </div>
</template>

<script>
  import constUrl from '@/services/env'
  import imgUrl from './zanwutupian.png'
  import {_isMobile} from '@/utils/index'

  export default {
    props: ['dataList', 'id'],
    computed: {
      projectList: {
        get() {
          if (this.id == '') {
            let dataList = []
            this.dataList.map(x => {
              x.itemList.map(y => {
                dataList.push(y)
              })

            })
            return dataList
          } else {
            return this.dataList.filter(x => x.id === this.id);
          }
        },
      }
    },
    data() {
      return {
        constUrl,
        imgSrc: imgUrl, isMobile: false,
        contain: 'contain'

      }
    },
    created() {
      if (_isMobile()) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    },
    methods: {
      toDetails(id, picture) {
        // this.$emit('topPic', picture)
        this.$router.push({
          path: `/product/detail/${id}`,
        })
      },
    },
    mounted() {
    }
  }
</script>
<style scoped lang="less">
  .productBox {
    /*width: 80%;*/
    height: 100%;
    margin: auto;
    margin-bottom: 1rem;

    ul {
      width: 100%;
      height: 100%;
      padding: 2% 0;

      .concentBox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        /*background: red;*/

        li {
          width: 16vw;
          margin-right: 1%;
          position: relative;
          overflow: hidden;
          margin-bottom: 1%;
          height: 12vw;
          box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);

          img {
            width: 100%;
            height: 12vw;
          }
        }
      }

      li:hover .details {
        opacity: 1;
        transform: translateY(0) scaleX(1);
      }
    }
  }

  .details {
    color: white;
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: rgba(0, 0, 0, 0.5);
    transform: translateY(100%) scaleX(0);
    transition: .4s ease-in-out;
    text-align: center;

    .details_title, .details_concent {
      font-size: 1.4rem;
      margin-top: 20%;
    }

    .details_concent {
      font-size: 1rem;
      margin-top: 5%;
    }

    .details_btn {
      width: 40%;
      margin: auto;
      margin-top: 1vw;

      .el-button {
        width: 100%;
        height: 10%;
        margin: auto;
        border-radius: 100px;
        background: #ff6969;
        border: none;
        line-height: 30%;
        color: white;
      }
    }
  }

  .detail {
    width: 90%;
    height: 48px;
    margin: auto;
    overflow: hidden;
    text-indent: 2em;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
</style>


<style scoped lang="less">
  .phoneProductBox {
    width: 80%;
    height: 100%;
    margin: auto;
    margin-bottom: 1rem;
    ul {
      width: 100%;
      height: 100%;
      padding: 2% 0;
      .concentBox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        li {
          width: 32%;
          margin-right: 1%;
          position: relative;
          overflow: hidden;
          margin-bottom: 1%;
          height: 24vw;
          box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
          img {
            width: 100%;
            height: 22vw;
          }
        }
      }
      li:hover .details {
        opacity: 1;
        transform: translateY(0) scaleX(1);
      }
    }
    .details {
      color: white;
      position: absolute;
      left: 0;
      bottom: 0px;
      width: 100%;
      height: 100%;
      opacity: 0;
      background: rgba(0, 0, 0, 0.5);
      transform: translateY(100%) scaleX(0);
      transition: .4s ease-in-out;
      text-align: center;

      .details_title, .details_concent {
        font-size:3rem;
        margin-top: 20%;
      }
      .details_concent {
        font-size: 2.2rem;
        margin-top: 0%;
        overflow: hidden;
        height:8vw;

      }
      .details_btn {
        width:80%;
        margin: auto;
        .el-button {
          width: 80%;
          height: 1px !important;
          margin: auto;
          border-radius: 1vw;
          background: #ff6969;
          border: none;
          line-height: 1vw;
          color: white;
          font-size: 2vw;
        }
      }
    }
    .detail {
      width: 90%;
      height: 5.4vw;
      margin: auto;
      overflow: hidden;
      text-indent: 2em;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }


</style>
